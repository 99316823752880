import { Paper, Grid, Typography, Button, Link } from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import CarouselItem from "./CarouselItem";

const carousel_items = [
  {
    name: "Random Name #1",
    description: "Probably the most random thing you have ever seen!",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
  },
  {
    name: "Random Name #3",
    description: "Hello World again!",
  },
];

const AppraisalFeatures = () => {
  const [active_carousel_item, set_active_carousel_item] = useState<number>(0);
  function change_carousel_item(new_carousel_item: number) {
    if (new_carousel_item >= carousel_items.length) {
      set_active_carousel_item(0);
    } else {
      set_active_carousel_item(new_carousel_item);
    }
  }

  return (
    <main>
      <Paper sx={{ backgroundColor: "#39B54A", pt: 4, pb: 4, pl: 4, pr: 4 }}>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography
              sx={{ color: "#fff" }}
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              <i>
                A BitLot appraisal can earn you hundreds more and save you weeks
                on the selling process.
              </i>
            </Typography>
            <Typography
              sx={{ color: "#fff" }}
              component="h5"
              variant="h5"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Post your appraised components and builds on eBay, Facebook
              Marketplace, and Craigslist, or send them to friends and family.
            </Typography>
            <Typography
              sx={{ color: "#fff" }}
              component="h5"
              variant="h5"
              align="center"
              color="textPrimary"
            >
              Make it an easy decision for your buyer.
            </Typography>
          </Grid>
          {/* <Grid item xs={6} sx={{margin: 'auto'}}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{color: '#fff', textAlign: 'center'}} component="h6" variant="h6">Who are you? BitLot can help you</Typography>
                </Grid>
                <Grid item xs={4} sx={{textAlign: 'center'}}>
                  <Button
                    disabled={active_carousel_item === 0}
                    sx={
                      {
                        fontSize: 15,
                        borderColor: '#fff',
                        '&:disabled': {
                          color: '#fff',
                        }
                      }
                    }
                    variant="contained"
                    onClick={() => change_carousel_item(0)}
                    
                  >
                    I want a desktop
                  </Button>
                </Grid>
                <Grid item xs={4} sx={{textAlign: 'center'}}>
                  <Button
                      disabled={active_carousel_item === 1}
                      sx={
                        {
                          fontSize: 15,
                          borderColor: '#fff',
                          '&:disabled': {
                            color: '#fff',
                          }
                        }
                      }
                      variant="contained"
                      onClick={() => change_carousel_item(1)}
                      
                    >
                      I own a desktop
                  </Button>
                </Grid>
                <Grid item xs={4} sx={{textAlign: 'center'}}>
                <Button
                      disabled={active_carousel_item === 2}
                      sx={
                        {
                          fontSize: 15,
                          borderColor: '#fff',
                          '&:disabled': {
                            color: '#fff',
                          }
                        }
                      }
                      variant="contained"
                      onClick={() => change_carousel_item(2)}
                      
                    >
                      I own many desktops
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Carousel
                // indicatorIconButtonProps={{style: {width: '200px', borderRadius: 0, fill: '100%'}}}
                // indicatorContainerProps={{style: {fill: '100%', backgroundColor: 'black'}}}
                onChange={(now) => change_carousel_item(now ?? 0)}
                index={active_carousel_item}
                indicatorContainerProps={{style: {display: 'none'}}}
                interval={10000}
              >
                  {
                      carousel_items.map( (item, i) => <CarouselItem key={i} item={item} /> )
                  }
              </Carousel>
            </Grid> */}
        </Grid>
      </Paper>
    </main>
  );
};

export default AppraisalFeatures;
